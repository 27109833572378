.titel-container{
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    width: 100vw;
    align-items: center;
}
.video{
    max-height: calc(80vh - 80px);
    max-width: 95vw;
    margin:20px;
    border-radius: 25px;
    background-color: rgb(0, 0, 0);
    
}

.titel-bordspel{
    width: 100vw;
    margin-top: 10em;
}

.titel-text-container{
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    flex-grow: 10;
    
}
.titel-text{
    max-width: 600px;
    font-family: reef;
    color: rgb(9,38,48)  ;
    font-size: 1.3em;
    font-style: normal;
    margin:4em;
}

.atano-name-container{
    flex-grow: 10;
    width: 100vw;
    height: 20px;
    margin:10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.atano-name{
    max-height: 100%;
    max-width: 90%;
}

.intro-container{
    position: relative;
    height: 100vh;
    width: 100vw;
    background-color: rgb(109,196,199);
    overflow: hidden;
}


.intro-text{
    position: absolute;
    left: 25vw;
    top:5vh;
    max-width: 300px;
    font-family: reef;
    color:white;
    font-size: 28px;
    filter: drop-shadow(2px 2px 3px rgb(9,38,48));
    transform: translate(-30%,0);
}
.intro-picture{
    position: absolute;
    bottom:0px;
    Right:45%;
    max-height: 95%;
    transform: translate(70%,0);
}
.situatie-container{
    position: relative;
    background: radial-gradient( rgb(9,38,48)50%, rgb(4, 18, 22)100% );
    height:auto;
    width: 100vw;
    height: 120vh;
}

.situatie-plattegrond-container{
    position: absolute;
    top:10vh;
    left:52vw;
    width: 53vh;
    height: 100vh;
    transform: translate(-20%,0);
}
.situatie-plattegrond{
    position: absolute;
    left:0;
    height: 100vh;
    z-index: 1;

}
.situatie-pijlen{
    position: absolute;
    left: 4vh;
    top:28vh;
    height: 45vh;
    z-index: 2;
    opacity: 0.8;
    filter: drop-shadow(2px 2px 5px black);
    -webkit-animation: rotating 20s linear infinite;
    -moz-animation: rotating 20s linear infinite;
    -ms-animation: rotating 20s linear infinite;
    -o-animation: rotating 20s linear infinite;
    animation: rotating 20s linear infinite;
}

.situatie-dorp{
    position: absolute;
    top:18vh;
    left:50vw;
    font-family: reef;
    font-size: 28px;
    width: 250px;
    color: white;
    filter: drop-shadow(2px 2px 5px black);

}

.situatie-route{
    position: absolute;
    top:50vh;
    left:50vw;
    width: 100px;
    font-family: reef;
    font-size: 28px;
    color: white;
    filter: drop-shadow(2px 2px 5px black);

}

.situatie-drakeneiland{
    position: absolute;
    top:90vh;
    left:50vw;
    width: 250px;
    transform: translate(-100%,0);
    font-family: reef;
    font-size: 28px;
    color: white;
    filter: drop-shadow(2px 2px 5px black);

}

