.denacht-container{
    position: relative;
    width: 100vw;
    height: 175vw;
    background-color: black;
}
.denacht-achtergrond{
    position: absolute;
    bottom: 0;
    width: 100vw;
    z-index: 0;
    
}
.denacht-personen{
    position: absolute;
    bottom: 0;
    width: 100vw;
    z-index: 2;

}
.denacht-draak{
    position: absolute;
    bottom: 55vw;
    width: calc(65vh + 40vw);
    z-index: 1;
}