.overdag-container{
    position: relative;
    height: 50vh;
    width: 100vw;
    overflow:hidden;
}

.torens-container{
    position: relative;
    height: 50vh;
    width: 100vw;
    overflow:hidden;
    
}
.torens-vignet{
    position: absolute;
    height: 100%;
    width: 100%;
    background: radial-gradient( rgba(16, 16, 16,0)25%, rgba(16, 16, 16,256)150% );
}
.wapens-gradient{
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom, rgb(9,38,48) , rgb(106, 200, 201), rgb(193, 242, 243), rgb(106, 200, 201), rgb(9,38,48));
   
}
.wapen01{
    position: absolute;
    height: 80vh;
    top: 3vh;
    Right:-2vw;
    z-index: 2;
}
.wapen02{
    position: absolute;
    height: 80vh;
    bottom: 5vh;
    left:-10vw;
}

.torens-imgclip{
    position: absolute;
    height: 100%;
    width: 100%;
    clip-path: inset(0px 0px 0px 0px);

}
.torens-sky{
    position: fixed;
    min-width: 100vw;
    min-height: 100vh;
    top:0;
    right:0;
}
.torens-sky2{
    position: fixed;
    min-width: 100vw;
    min-height: 100vh;
    top:0;
    left:50%;
    mix-blend-mode: overlay;
    transform: translate(-50%,0);
}
.torens-voor{
    position: absolute;
    height: 75vh;
    bottom: 0;
    left:50vw;
    transform: translate(-50%,0);
    z-index: 2;
}
.torens-achter{
    position: absolute;
    height: 75vh;
    bottom: 0;
    left:50vw;
    transform: translate(-50%,0);
}
.torens-parallax{
    position: absolute;
    height: 50vh;
    width: 100vw;
}
