@font-face {
    font-family: reef;
    src: url(Reef.otf);
  }

  @font-face {
    font-family: barbaro;
    src: url(barbaro.ttf);
  }

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(-360deg);
      -o-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(-360deg);
      -moz-transform: rotate(-360deg);
      -webkit-transform: rotate(-360deg);
      -o-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }
:root {
--off-white: #fafafa;
}

.uitvulling{
position: relative;
height: 50vh;
width: 100vw;
}
.uitvulling-black{
position: absolute;
top:-5px;
height: calc(50vh + 5px);
width: 100vw;
background-color: rgb(5,4,3)
}  
.background{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -4;
    background: linear-gradient(180deg, rgb(9,38,48) 0%, rgb(106, 200, 201) 100%);
}
.spacing{
    width: 100vw;
    height: 10vh;
}

.main-text{
    position: absolute;
    top:10vh;
    left:50vw;
    transform: translate(-50%,0);
    width: 400px;
    text-align: center;
}
h1{
    font-family: reef;
    color:white;
    font-size: 1.7em;
    font-style: normal;
}
h2{
    font-family: barbaro;
    color:white;
    font-size: 1.7em;
    font-weight: normal;
}

.pagina-naam{
    position: absolute;
    left:0px;
    top:0px;
    font-family: barbaro;
    color:white;
    font-size: 20px;
    opacity: 50%;
    filter: drop-shadow(1px 1px 2px black);

}
.test{
    position: fixed;
    left:0;
    top: 0;
    z-index: 10;
    color:white;
}

