.voorbereiding-container{

    height: 125vh;
    width: 100vw;
    overflow: hidden;
    background-color:rgb(9,38,48) ;

}
.voorbereiding-controller{
    position: absolute;
    left:50vw;
    top: 45vh;
    height: 30vh;
    filter: drop-shadow(2px 2px 5px black);
}
.voorbereiding-tijd{
    position: absolute;
    top:35vh;
    Left: 50vw;
    width: 350px;
    text-align:left;
    filter: drop-shadow(2px 2px 5px black);
}
.voorbereiding-taktiek{
    position: absolute;
    bottom:35vh;
    left: 50vw;
    width: 350px;
    text-align: right;
    filter: drop-shadow(2px 2px 5px black);
}
.tactiek-container{
    position: relative;
    height: 120vh;
    width: 100vw;
}
.tactiek-logo{
    position: absolute;
    width: 25vh;
    left: 50vw;
    top: 45vh;
    transform: translate(-50%,-50%);
    z-index: 3;
}
.tactiek5{
    position: fixed;
    width: 50vh;
    left: 35vw;
    bottom: 0;
    z-index: 2;
}
.tactiek6{
    position: fixed;
    width: 60vh;
    left: 65vw;
    bottom: 0;
    z-index: 2;
}
.tactiek7{
    position: fixed;
    width: 70vh;
    left: 35vw;
    bottom: 25%;
    z-index: 1;
}
.tactiek9{
    position: fixed;
    width: 90vh;
    left: 60vw;
    bottom: 10%;
    z-index: 1;
}
.tactiek10{
    position: absolute;
    width: 85vh;
    left: 40vw;
    top: -80%;
    z-index: 0;
}
.taktiek-gradient{
    position: absolute;
    bottom: 0;
    width:100vw;
    height: 120vh;
    background-color:rgb(9,38,48) ;
    z-index: 0;

}
.display-off{
    display: none;
}