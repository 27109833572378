@font-face {
    font-family: barbaro;
    src: url(barbaro.ttf);
  }

  @font-face {
    font-family: Bentham;
    src: url(Bentham.ttf);
  }

.navbar-enabled {
    height: 80px;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 10;
    overflow: hidden;
 }
 .navbar-disabled {
    height: 80px;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 10;
    overflow: hidden;
 }

 .blackStroke{
    display: block;
    position: absolute;
    background: linear-gradient(0deg, rgb(9,38,48) 0%, rgb(4, 18, 22) 100%);
    height: 80px;
    width: 100vw;
    z-index: -2;
 }


.navbar-container-enabled{
    display: block;
    position: absolute;
    height: 80px;
    top:0px;
    right:25px;
    width: auto;
    display: flex;
    align-items: center;
    z-index: -1;
    transition: all 0.3s ease-out;
}
.navbar-container-disabled{
    display: block;
    position: absolute;
    height: 80px;
    top:0px;
    right:-80vw;
    width: auto;
    display: flex;
    align-items: center;
    z-index: -1;
}

.navbar-link{
    font-family: barbaro;
    margin-Right: 25px;
    margin-left:25px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor:pointer;
    color: white;
    font-size: 25px;
}
.navbar-socials{
    margin-top: 10px;
    position: relative;
    height: 30px;
    width: 80px;
    margin-right: 20px;
    margin-left: 20px;
}

.navbar-Facebook{
    position: absolute;
    height: 100%;
    cursor:pointer;
}

.navbar-Instagram{
    position: absolute;
    right: 0;
    height: 100%;
    cursor:pointer;

}
.navbar-link-dark{
    font-family: barbaro;
    margin-Right: 25px;
    margin-left:25px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor:pointer;
    color:  rgb(142, 175, 186);
    font-size: 25px;
}

.navbar-logo-menuOn{
    display: block;
    position: absolute;
    left:10px;
    top : 0px;
    height: 60px;
    margin:10px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s ease-out;
    
}
.navbar-logo-menuOff{
    display: block;
    position: absolute;
    left:calc(50vw - 20px);
    top : 0px;
    height: 60px;
    margin:10px;
    cursor: pointer;
    text-decoration: none;
    
}

.menuIcon-enabled{
    display: block;
    position: absolute;
    width: 40px;
    right:10px;
    height: 40px;
    margin:20px;
    cursor:pointer
}

.menuIcon-disabled{
    display: none;

}

@media screen and (max-width: 960px) {
    .navbar-enabled {
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        z-index: 10;
        overflow: hidden;
     }
     .navbar-disabled {
        height: 80px;
        width: 100vw;
        position: fixed;
        top: 0;
        z-index: 10;
        overflow: hidden;
     }

    .navbar-container-disabled{
        position: absolute;
        background-color:  rgb(37, 77, 89);
        flex-direction: column;
        top: -100vh;
        left:0;
        right:0;
        transition: all 0.6s ease-out;
        height: 100vh;
        margin:0;
        width: 100vw;
        z-index: -3;
        opacity: 0%;
    }
    .navbar-container-enabled{
        position: absolute;
        background-color:  rgb(37, 77, 89);
        flex-direction: column;
        top: 80px;
        transition: all 0.6s ease-out;
        height: 100vh;
        margin:0;
        width: 100vw;
        z-index: -3;
        right:auto;
    }

    .navbar-logo-menuOn{
        display: block;
        position: absolute;
        left:calc(50vw - 20px);
        top : 0px;
        height: 60px;
        margin:10px;
        cursor: pointer;
        text-decoration: none;
        
    }


    
}