@font-face {
    font-family: Bentham;
    src: url(Bentham.ttf);
  }

.Ontwerper-container{
    width: 100vw;
    overflow:auto;
    background: linear-gradient(0deg, rgb(0, 0, 0) 80%, rgb(6, 6, 6) 100%);

}
.text-container{
    margin-top:200px;
    margin-bottom: 200px;
    width: 500px;
    overflow:auto;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family:Bentham;
    color:white;
    font-size: 1.2em;
    font-style:normal;

}
.text-center{
    text-align: center;

}
.Line{
    margin-top: 50px;
    width: 100%;
    height: 2px;
    margin-bottom: 29px;
    background-color: rgb(106,200,201);
}
.Ontwerper-logo{
    height: 150px;
}

.navbar-socials{
    margin-top: 10px;
    position: relative;
    height: 30px;
    width: 80px;
    margin-right: 20px;
    margin-left: 20px;
}

.Facebook{
    position: absolute;
    height: 100%;
    cursor:pointer;
}

.Instagram{
    position: absolute;
    right: 0;
    height: 100%;
    cursor:pointer;

}

